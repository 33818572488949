import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SKIPLOADING } from '../interceptor/httpi.interceptor';
// import { PixelService } from 'ngx-multi-pixel';

@Injectable({
  providedIn: 'root',
})
export class MarketingService {
  constructor(private http: HttpClient) {} // private pixel: PixelService

  sendPixel(location: string, button: string) {
    // this.pixel.trackCustom(button, {
    //   location: location,
    // });
  }
  scrollToElement(element: HTMLElement): void {
    element.scrollIntoView({ inline: 'start' });
  }
  getVideosUrl() {
    return this.http.get(`${environment.apiUrl}/api/get_landing_videos`, {
      context: new HttpContext().set(SKIPLOADING, true),
    });
  }
}
